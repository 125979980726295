#paint-app {
  justify-content: space-between;
}

#paint-app-welcome-main {
  padding: 2em;
  background: #1e90ff;
  color: white;
}

#paint-app-welcome-main h1 {
  line-height: 1.5;
  border-left: white solid 8px;
  padding-left: 16px;
}

#paint-app-welcome-main h1 > span {
  display: block;
  color: black;
  text-transform: uppercase;
  font-weight: bold;
}

#paint-app-welcome-main ol {
  align-self: center;
  list-style-type: none;
  padding: 0;
  margin-left: 3em;
}

#paint-app-welcome-main ol > li {
  margin: 0 1.5em;
}

#paint-app-welcome-main ol > li > a {
  color: white;
  opacity: 0.6;
  transition: 200ms;
}

#paint-app-welcome-main ol > li > a:hover,
#paint-app-welcome-main ol > li > a:focus {
  opacity: 1;
}

#paint-app-welcome-main ol > li svg {
  display: block;
}

#paint-app-welcome-footer {
  justify-content: space-between;
  border-top: solid #1e90ff 1px;
  color: #333;
  padding: 0.5em 1em;
  margin: 0 2em;
}

#paint-app-welcome-footer a {
  color: inherit;
}
