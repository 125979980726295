html,
body,
div#root {
  height: 100%;
}

#paint-app-header {
  position: fixed;
  height: 60px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: block;
  background: #1e90ff;
  color: white;
}

#paint-app-header h1 {
  font-size: 1.8em;
  line-height: 60px;
  margin: 0 1em;
  display: inline;
}

#paint-app-header a {
  float: right;
  color: white;
  line-height: 60px;
  margin-right: 1rem;
}

#paint-app-content {
  margin-top: 64px;
}
